export abstract class AppConfig {
  public abstract API_BASE: string; // No trailing slash
  public abstract IDENTITY_BASE: string; // No trailing slash
  public abstract LOSTBAR_API_BASE: string; // No trailing slash
  public abstract HUBSPOT_BASE: string; // No trailing slash
  public abstract COOKIE_YES_SLUG: string;
  public abstract EATER_FEEDBACK_ENABLED: boolean;
  public abstract VERSION: string;
  public abstract GIT_SHORT_SHA: string;
  public abstract FRONTEND_BASE: string;
  public abstract INCLUDE_ZENDESK: boolean;
  public abstract GOOGLE_ANALYTICS_ID: string;
  public abstract SEGMENT_ID: string;
  public abstract HUBSPOT_ID: string;
  public abstract HUBSPOT_ENABLED: boolean;
  public abstract SAME_DAY_DELIVERY_ENABLED: boolean;
  public abstract LATEST_ORDER_HOURS: number;
  public abstract SENTRY_ENABLED: boolean;
  public abstract BRAINTREE_ENV: 'sandbox' | 'production';
  public abstract ENV: string;
  public abstract STRIPE_PUBLIC_KEY: string;
  public abstract STRIPE_INDIVIDUAL_ORDERING_PUBLIC_KEY: string;
  public abstract ADDRESS_AUTOCOMPLETE_ENABLED: boolean;
  public abstract SSO_ENABLED: boolean;
}
