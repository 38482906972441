import { Provider } from '@angular/core';
import { AppConfig } from './app-config';

export class MockAppConfig extends AppConfig {
  public API_BASE: string = 'http://citypantry.test/api';
  public IDENTITY_BASE: string = 'http://id.citypantry.test/api';
  public LOSTBAR_API_BASE: string = 'http://citypantry.test/lostbar';
  public HUBSPOT_BASE: string = 'http://citypantry.test';
  public EATER_FEEDBACK_ENABLED: boolean = false;
  public COOKIE_YES_SLUG: string = '';
  public VERSION: string = 'test';
  public GIT_SHORT_SHA: string = '';
  public FRONTEND_BASE: string = 'http://citypantry.test';
  public INCLUDE_ZENDESK: boolean = false;
  public GOOGLE_ANALYTICS_ID: string = 'ga-test-id';
  public SEGMENT_ID: string = 'segment-test-id';
  public HUBSPOT_ID: string = 'hubspot-test-id';
  public HUBSPOT_ENABLED: boolean = false;
  public SAME_DAY_DELIVERY_ENABLED: boolean = false;
  public LATEST_ORDER_HOURS: number = 21;
  public SENTRY_ENABLED: false;
  public BRAINTREE_ENV: 'sandbox';
  public ENV: 'dev';
  public STRIPE_PUBLIC_KEY: string;
  public STRIPE_INDIVIDUAL_ORDERING_PUBLIC_KEY: string;
  public ADDRESS_AUTOCOMPLETE_ENABLED: boolean = false;
  public SSO_ENABLED: boolean = false;
}

export const MockAppConfigProvider: Provider = {
  provide: AppConfig,
  useClass: MockAppConfig
};
